import { combineReducers } from "redux";
import simulatorReducer from "./simulatorReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const rootReducer = combineReducers({
  // simulator: simulatorReducer,
  simulator: persistReducer(
    {
      key: "auth",
      storage,
      blacklist: ["error"],
      whitelist: ["token"],
    },
    simulatorReducer
  ),
});
