/* eslint-disable */

export default {
  API_URL: process.env.REACT_APP_API_ROOT,
  WEBVIEW_URL: process.env.REACT_APP_WEBVIEW_URL,
  WEBVIEW_URL_FULL: process.env.REACT_APP_WEBVIEW_URL_FULL,
  MERCHANT_URL: process.env.REACT_APP_MERCHANT_URL,
  MERCHANT_URL_FULL: process.env.REACT_APP_MERCHANT_URL_FULL,
};

export const constant = {
  REGEX_CHAR_ONLY: /^[a-zA-Z ]*$/,
  REGEX_PHONE_NUMBER: /^08[0-9]{9,12}$/,
  REGEX_PHONE_NUMBER2: /^8[0-9]{8,11}$/,
  REGEX_EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  REGEX_CREDIT_CARD:
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,

  URL_XENDIT_AUTH: 'sandbox/credit-card/credit-card-auth',
  URL_XENDIT_DETAIL: 'sandbox/credit-card/transaction-detail/',
  URL_VA_SIMULATOR: 'sandbox/webview/bersama/simulator',
  URL_BCA_VA_SIMULATOR: 'sandbox/bca/submit-va',
  URL_GET_BRIVA: 'sandbox/bri/status?VirtualAccountNumber=',
  URL_POST_BRIVA: 'sandbox/bri/pay',

  URL_PAYMENT_LIST: 'vasimulator/list_payment_channel',
  URL_GET_DETAIL: 'vasimulator/webview/detail',
  URL_PAY: 'vasimulator/webview/pay',
  URL_SUBMIT_UID: 'espaysimulator/request_ios',

  // URL_LINK_AJA: APP_CONFIG.ESPAY_URL,
  // URL_LINKAJASIM: APP_CONFIG.URL_LINKAJASIM,
  // URL_OVOSIM: APP_CONFIG.URL_OVOSIM,

  // URL_CC: APP_CONFIG.CC_URL,

  URL_KLIK_LOGIN_SIMULATOR: 'sandbox/bca/login/',
  URL_KLIK_LOGIN_PAYMENT: 'sandbox/bca/login-next/',
  URL_KLIK_GET_DETAIL: 'sandbox/bca/detail-klikpay/',
  URL_KLIK_SUBMIT: 'sandbox/bca/submit-klikpay',

  URL_GET_VA: 'sandbox/va/simulator/detail?va-number=',
  URL_SUBMIT_VA: 'sandbox/va/simulator/pay',
  URL_GET_QRIS_SIMULATOR: 'v1/simulator/qris/detail?order_no=',
  URL_PAY_QRIS_SIMULATOR: 'v1/simulator/qris/pay',
  URL_QRIS_GENERATE: 'qris/generates',
};
